<template>
    <div class="appointment-item-card-wrapper">
      <div class="item-card-header">
        <span class="type">{{ parseType(billItem) }}</span>
        {{ billItem.item?.name || billItem.item?.description }}
      </div>
      <hr/>
      <div class="row">
        <div class="col col-6">
          <b-form-group>
            <label>Convênio</label>
            <multiselect
              class="with-border"
              track-by="id"
              label="fantasy_name"
              v-model="billItem.health_plan"
              :searchable="false"
              :showLabels="false"
              :allow-empty="false"
              placeholder="Selecionar"
              :options="healthPlans"
              :disabled="disabled"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nenhum convênio adionado ao paciente
              </template>
              <template slot="noResult">
                Convênio informado não vinculado ao paciente
              </template>
            </multiselect>
            <div v-if="validated && !billItem.health_plan" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </div>
        <div class="col col-6">
          <b-form-group>
            <label>
              Plano
              <span class="help">(opcional)</span>
            </label>
            <multiselect
              v-model="billItem.plan"
              :options="allPlans.filter(el => el.health_plan_id === billItem.health_plan?.id)"
              track-by="value"
              label="name"
              :showLabels="false"
              :searchable="true"
              placeholder="Plano (opcional)"
              class="with-border full-width"
              :disabled="disabled"
              @select="plan => selectPlan(plan, index)"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Não foram encontrados planos
              </template>
              <template slot="noResult">
                Plano informado não identificado ou não cadastrado
              </template>
            </multiselect>
          </b-form-group>
        </div>

        <div class="col col-3">
          <b-form-group>
            <label>Valor do item</label>
            <money
              class="form-control money"
              v-model="billItem.value"
              :disabled="disabledMonetaryFields"
              :class="{ 'red-border': parseFloat(billItem.value) < 0}"
            />
            <div v-if="validated && !billItem.value && !cortesia " class="custom-invalid-feedback">Campo obrigatório</div>
            <div v-if="parseFloat(billItem.value) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
          </b-form-group>
        </div>
        <div class="col col-2">
          <b-form-group>
            <label>Desconto</label>
            <b-form-input
              v-if="billItem.format === '%'"
              type="number"
              v-model="billItem.discount"
              :class="{ 'red-border': parseFloat(billItem.discount) < 0}"
              :disabled="disabled"
            />
            <money
              v-else
              class="form-control money"
              v-model="billItem.discount"
              :class="{ 'red-border': parseFloat(billItem.discount) < 0}"
              :disabled="disabled"
            />
            <div v-if="parseFloat(billItem.discount) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
          </b-form-group>
        </div>
        <div class="col col-2">
          <b-form-group>
            <label>Acréscimo</label>
            <b-form-input
              v-if="billItem.format === '%'"
              type="number"
              v-model="billItem.addition"
              :class="{ 'red-border': parseFloat(billItem.addition) < 0}"
              :disabled="disabled"
            />
            <money
              v-else
              class="form-control money"
              v-model="billItem.addition"
              :class="{ 'red-border': parseFloat(billItem.addition) < 0}"
              :disabled="disabled"
            />
            <div v-if="parseFloat(billItem.addition) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
          </b-form-group>
        </div>
        <div class="col col-1">
          <b-form-group>
            <label>$ ou %</label>
            <multiselect
              v-model="billItem.format"
              class="with-border"
              :options="['$', '%']"
              :showLabels="false"
              :internalSearch="false"
              :searchable="false"
              :allow-empty="false"
              :disabled="disabled"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nenhuma opção
              </template>
              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="col col-2">
          <b-form-group>
            <label>Valor final</label>
            <money
              disabled
              class="form-control money"
              :value="billItem.final_value"
            />
          </b-form-group>
        </div>
        <div class="col col-2">
          <b-form-group>
            <label>Valor líquido</label>
            <money
              disabled
              class="form-control money"
              :value="billItem.net_value"
            />
          </b-form-group>
        </div>
  
        <div class="col col-12">
          <OnlendingProfilesModule
            v-if="showOnlending() && !(billItem.health_plan?.type === 'public_health' && billItem.health_plan?.is_sus_health_plan === 1)"
            v-show-feature="'onlending'"
            v-can="'RepMedPerf1'"
            :item="billItem.item"
            :itemProfiles="billItem.item_profiles"
            @change="updateBillItem"
          />
        </div>
      </div>
      <div v-if="!disabled" class="remove" @click="$emit('remove', billItem)">
        Remover item
      </div>
    </div>
  </template>
  <script>
  import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
  import { parseType } from '@/utils/itemHelper'
  import { showOnlending } from '@/utils/permissionsHelp';

  export default {
    components: { 
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        OnlendingProfilesModule: () => import('@/components/General/OnlendingProfilesModule'),
    },
    props: {
      billItem: Object,
      healthPlans: Array,
      allPlans: Array,
      validated: Boolean,
      disabled: Boolean,
      cortesia: Boolean,
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        formats: [
          { label: 'R$', value: '$' },
          { label: '%', value: '%' },
        ],
        userCanEditValues: false,
      }
    },
     created() {
      this.userCanEditValues = userHasPermission('FpAgen7')
    },
    computed: {
      disabledMonetaryFields() {
        return this.disabled || !this.userCanEditValues
      }
    },
    methods: {
      showOnlending,
      parseType,
      updateBillItem(itemProfiles) {
        this.billItem.item_profiles = itemProfiles
      },
      calculateTotalValue() {
        let total = 0;
        if (this.billItem.format === '$') {
          total = this.billItem.value + (-Math.abs(this.billItem.discount) + Math.abs(this.billItem.addition))
        } else if (this.billItem.format === '%') {
          const target = -Math.abs(this.billItem.discount) + Math.abs(this.billItem.addition)
          total = this.billItem.value + (this.billItem.value * target) / 100
        }
        this.billItem.final_value = total;
      },
      getItemPrice() {
        if (!this.billItem || !this.billItem.health_plan) return
        this.api.getItemPrice(this.clinic.id, this.billItem.item.item_type, this.billItem.item.id, this.billItem.health_plan.id, this.billItem.plan?.id)
        .then(res => {
          this.billItem.value = res.data.price
        })
        .catch(err => this.$toast.error(err.message))
      },
    },
    watch: {
      'billItem.health_plan': function() { this.getItemPrice() },
      'billItem.plan': function() { this.getItemPrice() },
      'billItem.value': function() { this.calculateTotalValue() },
      'billItem.discount': function() { this.calculateTotalValue() },
      'billItem.addition': function() { this.calculateTotalValue() },
      'billItem.format': function() { this.calculateTotalValue() },
    }
  }
</script>
<style lang="scss" scoped>
  .appointment-item-card-wrapper {
    padding: 15px;
    border-radius: 8px;
    border: 1px dashed var(--neutral-200);
    background-color: var(--neutral-100);
    color: var(--type-active);
    margin: 10px 0;
    .item-card-header {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .type {
      color: var(--blue-500);
      font-weight: 600;
    }
    .remove {
      display: inline-flex;
      font-weight: 600;
      font-size: 14px;
      color: var(--states-error);
      cursor: pointer;
    }
    .col {
      padding-right: 2px;
      padding-left: 2px;
    }
    .form-group {
      margin-bottom: 12px;
    }
    .red-border{
      border: 0.66px red solid !important;
      color: red !important;
    }
  }
</style>
  